import React from 'react';

/* eslint-disable */
export default () => (
  <div className="contract_page text-center">
    <h2>Együttműködése megállapodás küldemények továbbítására</h2>
    <p>
      továbbiakban: „<strong>Megállapodás</strong>” mely a mai napon és helyen
      egyrészről
    </p>
    <p>
      <strong>Jelen regisztrációt végző cég, </strong>
      mint együttműködő partner (a továbbiakban: „Partner” vagy „Feladó”)
    </p>
    <p>másrészről a</p>
    <p>
      <strong />
    </p>
    <p>
      <strong>Cargo-Viszed Korlátolt Felelősségű Társaság</strong>
      (székhely: 9700 Szombathely, Aranypatak u. 3. cégjegyzékszám:
      18-09-7113337; adószám: 26083579-2-18, képviselő: Kratochvill Dóra
      ügyvezető, önállóan; telefonszám: +36707582536, email cím: info@viddl.hu
      mint együttműködő partner (a továbbiakban: „<strong>ViddL</strong>
      ”)
    </p>
    <p>
      külön-külön „<strong>Fél</strong>
      ”, együttesen „<strong>Felek</strong>” között az alulírott napon és
      helyen, az alábbi tartalommal jött létre.
    </p>
    <p>
      <strong />
    </p>
    <p>
      <strong>Preambulum</strong>
    </p>
    <p>
      A) A Partner kijelenti, hogy Magyarországon bejegyzett és működő gazdasági
      társaság.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      B) A ViddL kijelenti, hogy Magyarországon bejegyzett és működő gazdasági
      társaság, mely online áruszállítási platformján keresztül futárpostai
      szolgáltatás nyújtását végzi, rendelkezik minden arra vonatkozó
      engedéllyel, bejelentéssel és azokat jelen szerződés hatálya alatt
      érvényben és hatályban is tartja.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      C) Szerződő Felek megállapodnak abban, hogy jelen Megállapodás 1.
      pontjában részletezett tevékenységük során együtt kívánnak működni
      egymással.
    </p>
    <p>
      D) Jelen Szerződésre a ViddL{" "}
      <a href="http://www.viddl.hu">www.viddl.hu</a>
      felületén található ÁSZF-je mindenkor irányadó.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      <strong>1. </strong>
      <strong>A Megállapodás tárgya és időtartama</strong>
    </p>
    <p>
      1.1.A Szerződő Felek megállapodnak abban, hogy kölcsönös együttműködésük
      révén a ViddL futárpostai szolgáltatást kíván nyújtani Partner részére oly
      módon, hogy amennyiben a Partner az ügyfelei (a továbbiakban: „{" "}
      <strong>Ügyfél</strong>
      ”) a Partner felületén az általuk megvásárolt termékek (a továbbiakban: „
      <strong>Küldemény</strong>
      ”) tekintetében a ViddL szállítási módot használják, a ViddL közreműködői
      segítségével (a továbbiakban: „<strong>Futár</strong>) eljuttatja
      kizárólag Budapest közigazgatási területén belül az Ügyfél által
      meghatározott szállítási címre. A ViddL által végzett futárpostai
      szolgáltatási tevékenység során Felek mindvégig használják a ViddL által
      kifejlesztett online áruszállítási platformot (a továbbiakban: „
      <strong>Platform</strong>
      ”).
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      1.2.Jelen Megállapodás határozatlan időtartamra szól.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      <strong>2. </strong>
      <strong>A Felek jogai és kötelezettségei</strong>
    </p>
    <p>
      2.1.A Felek kölcsönösen vállalják, hogy jelen Megállapodásban foglalt
      kötelezettségeik teljesítése során úgy kötelesek eljárni, miként az a
      szakmai elvek alapján elvárható, a másik Fél részére az együttműködés
      vonatkozásában akár az online végzendő, akár a Küldeményszállítási
      tevékenység esetében a maximális információt rendelkezésre bocsátják, és
      az együttműködés sikere érdekében a lehető legnagyobb mértékben
      együttműködnek.
    </p>
    <p>
      2.2.A Felek megállapodnak, hogy a jogviszony során esetlegesen tudomására
      jutott valamennyi tény, adat, információ vagy megoldás, különösen a Felek
      fejlesztési, üzemeltetési tevékenységéről szóló valamennyi tervezet,
      módszer vagy bármely egyéb információ üzleti titkot képez. Azok a másik
      fél előzetes írásbeli hozzájárulása nélkül kívülálló harmadik fél részére
      semmilyen körülmények között nem szolgáltathatók ki.
      <strong />
    </p>
    <p>
      2.3.Partner kijelenti, hogy mindenkor megfelelően biztosítja és szavatolja
      az EU-s és a magyar adatvédelmi rendelkezések megfelelő teljesítését
      tevékenysége során a külön nyilatkozatban foglaltaknak megfelelőn,
      különösen ide értve a Címzettre vonatkozó személyes adatok jogszerű
      kezelését és ViddL számára történő jogszerű továbbítást.
    </p>
    <p>2.4.A Partner jogai és kötelezettségei</p>
    <p>
      2.4.1. Partner a jelen jogviszonyban Feladónak minősül, és köteles minden,
      az ÁSZF-ben és a vonatkozó jogszabályokban rögzített feltételeknek való
      megfelelését mindenkor biztosítani.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      2.4.2. A Partner a jelen Megállapodás keretében végzett tevékenységével
      kapcsolatos üzleti kockázatot vállalja és viseli az összes költséget,
      valamint a tevékenység végzéséhez tartozó adókat és illetékeket, valamint
      a fenntartási, fejlesztési, beruházási ráfordításokat is, jelen
      Megállapodásban a ViddL kötelezettségeként említett kivételével.{" "}
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      2.4.3. A Partner vállalja, hogy a ViddL-t, mint szállítási módot jól
      láthatóan megjeleníti a weboldalán, az esetleges egyéb szállítási módok
      nem kapnak kiemelt helyet a ViddL-hez képest a felületén.
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      A Partner kijelenti, hogy sem közvetve sem közvetlenül nem próbálja
      megkerülni a ViddL szállítási módot, minden egyes ViddL platformon
      keresztül realizált fuvar esetén közreműködik a ViddL-el. Amennyiben ennek
      ellenkezője a ViddL tudomására jut, jogosult a ViddL jelen szerződést
      haladéktalanul azonnali hatállyal felmondani.
    </p>
    <p>
      2.4.4. A Partner felelősségére, különösen ide értve a Küldeményre
      vonatkozó felelősségét, az ÁSZF-ben és a vonatkozó jogszabályokban
      foglaltak irányadók.
    </p>
    <p>
      <strong />
    </p>
    <p>2.5.A ViddL jogai és kötelezettségei</p>
    <p>
      2.5.1. A ViddL vállalja, hogy a kézbesítendő Küldeményeket minden esetben
      eljuttatja az Címzettnek, ide nem értve, ha azzal, hogy a futárpostai
      szolgáltatás időbeli ütemezésében előfordulhat csúszás, melyért
      felelősséget nem vállal a ViddL. Egyebekben a ViddL felelősségére az
      ÁSZF-ben rögzítettek irányadók.
      <strong />
    </p>
    <p>
      2.5.2. A ViddL vállalja, hogy az általa alkalmazott Futárok
      tevékenységüket kizárólag Magyarországon bejelentett egyéni vállalkozóként
      vagy gazdasági társaságként végezhetik.
    </p>
    <p>
      2.5.3. A ViddL kötelezettséget vállal arra, hogy a jelen Megállapodás
      hatálya alá tartozó futárpostai és kapcsolódó feladatok ellátására
      vonatkozó általános és szakmai követelményeknek megfelelően jár el.{" "}
      <strong />
    </p>
    <p>
      <strong />
    </p>
    <p>
      <strong>3. </strong>
      <strong>A Szolgáltatás díja és fizetési feltételei</strong>
    </p>
    <p>
      3.1.A Felek együttműködésük során a következő rendszerben számolnak el
      egymással: a Partner köteles feltölteni a ViddL Egyenlegét az ÁSZF-ben
      írtaknak megfelelően, ahonnan a Szállítási díj előzetesen kerül levonásra
      az Egyedi feladói szerződés megkötését követően az ÁSZF szerint.
    </p>
    <p>
      3.2.A Küldemény felvételének sikertelensége esetén a Feladó által
      előzetesen megfizetett díjazással kapcsolatos elszámolás az alábbiak
      szerint alakul:
    </p>
    <p>
      a) a Feladó nem volt jelen, és sem a Futár, sem a ViddL nem tudta elérni
      telefonon sem. Ekkor az egyedi szerződés Feladó által felmondottnak
      minősül és a Szolgáltató az elszámolás keretében jóváírja a Feladó
      Egyenlegén az Szállítási 50%-át. Egyéb díj, költség, kártalanítás vagy
      kártérítés megfizetésére Szolgáltató a Feladó tekintetében nem köteles.
    </p>
    <p>
      b) a Futár nem ért oda az előzetesen jelzett időben, és nem tudott új
      időpontot egyeztetni a Feladóval. Ebben az esetben az Egyedi feladói
      szerződés teljesítését másik Futár útján végzi el a ViddL.
    </p>
    <p>
      c) A Futár az ÁSZF-fel összhangban jogszerűen tagadta meg a Küldemény
      felvételét. Ilyen esetben az Egyedi szerződés megszűnik és a Szolgáltató
      az elszámolás keretében jóváírja a Feladó Egyenlegén az Szállítási díj
      50%-át. Egyéb díj, költség, kártalanítás vagy kártérítés megfizetésére
      Szolgáltató a Feladó tekintetében nem köteles.
    </p>
    <p>
      d) Egyéb okból nem került sor a Küldemény felvételére a Futárnak felróható
      okból. Ebben az esetben az Egyedi feladói szerződés teljesítését másik
      Futár útján végzi el a ViddL.
    </p>
    <p>
      3.3.A Küldemény kézbesítésének sikertelensége esetén a Feladó által
      előzetesen megfizetett díjazással kapcsolatos elszámolás az alábbiak
      szerint jogosult:
    </p>
    <p>
      a) Amennyiben bármely okból nem kerül sor a Küldemény kézbesítésére,
      például a Címzett nem volt jelen, vagy ha a Futár nem ért oda az
      előzetesen jelzett időben, és sem a Futár, sem a ViddL nem tudott új
      időpontot egyeztetni a Címzettel, így a Küldemény sem a nap folyamán, sem
      azt követő 4 munkanapon belül nem került átadásra a Címzettnek bármely
      okból, úgy egyeztetést követően a Feladó képviselője vagy személyesen
      átveszi a Küldeményt vagy a ViddL egy Futárja fuvarozza el visszáruként a
      Küldeményt a Feladó irodájába. A visszáruként való fuvarozás díja az
      Alapdíj és a méret függvényében kerül meghatározásra, mely levonásra kerül
      a Feladó Egyenlegről.
    </p>
    <p>
      b) Az aláírás Címzett által történő megtagadása esetén a ViddL a
      Küldeményt – az ok feltüntetésével – visszaküldi a Feladónak, ilyen
      esetben a Feladó nem jogosult a megfizetett Szállítási díj
      visszatérítésére.
    </p>
    <p>
      3.4. A ViddL minden Egyenlegfeltöltésről elektronikus számlát állít ki és
      a feltöltés jóváírását követően haladéktalanul megküldi a Partner részére.
      Felek közötti tényleges teljesítésre vonatkozó elszámolás heti
      rendszerességgel történik oly módon, a ViddL hetente a ViddL rendszerből
      exportálja Partner összes Küldeményének adott heti forgalmát és az ebből
      származó bevételeket összevontan, egyedi azonosítószámmal ellátott
      elszámolási riport keretében megküldi Partnernek. Az elszámolási riport
      kézhezvételét követő 3 naptári napon belül Partner jogosult annak
      tartalmát érdemben kifogásolni az info@viddl.hu email címen, csatolva a
      kifogás részletes indoklását és az azt alátámasztó bizonyítékait. Ennek,
      vagy érdemi kifogásnak a hiányában az Elszámolási riportban szereplő
      Szolgáltatási díj Partner által elfogadottnak minősül, a továbbiakban
      érvényesen nem vitatható.
    </p>
    <p>
      <strong>4. </strong>
      <strong>A Megállapodás módosítása, megszűnése</strong>
    </p>
    <p>
      4.1.Jelen Megállapodást Felek kizárólag közös megegyezéssel, írásban
      módosíthatják.
    </p>
    <p>
      4.2.Amennyiben ViddL tudomására jut, hogy Partner nem felel meg az
      ÁSZF-ben, a jelen Megállapodásban, vagy az Egyedi szerződésben
      foglaltaknak, illetőleg nem teljesíti kapcsolódó kötelezettségeit, a ViddL
      jogosult a Partnert a ViddL rendszerben haladéktalanul, vonatkozó
      figyelmeztetést követően, bármely kapcsolódó kártérítési, kártalanítási
      kötelezettség nélkül felfüggeszteni addig, amíg a Partner a
      Megállapodásszegést megfelelően nem orvosolja. Amennyiben ez a
      felfüggesztést követő 3 hónapon belül sem történik meg, a ViddL jogosult
      további figyelmeztetés és bármely vonatkozó kártérítési, kártalanítási
      kötelezettség nélkül saját hatáskörben Partnert a ViddL rendszerből
      törölni és jelen Megállapodást azonnali hatállyal felmondani.
    </p>
    <p>
      4.3.A jelen Megállapodás az alábbi események közül már az első
      bekövetkezésekor megszűnik:
    </p>
    <p>a) a Felek a jelen Megállapodást közös megegyezéssel megszüntetik;</p>
    <p>
      b) A Fél felmondja a Megállapodást, mely 30 (harminc) napos felmondási
      időt követően válik hatályossá;
    </p>
    <p>
      c) a jelen Megállapodás aláírásának időpontjától tizenkét (12) hónap
      eltelik, és a Megállapodás szerinti együttműködés nem kezdődik meg;
    </p>
    <p>
      d) bármelyik Fél ellen felszámolási eljárás, végelszámolási eljárás vagy
      csődeljárás kezdeményezésére kerül sor, vagy bármelyik Fél hitelezői
      javára tett engedményezés következik be;
    </p>
    <p>e) a ViddL jogutód nélküli megszűnése esetén</p>
    <p>
      <a name="_Hlk514249837">f) a jelen Megállapodás, </a>
      <a name="_Hlk514249889">
        a vonatkozó ÁSZF, illetőleg az Egyedi szerződés
      </a>
      bármely Fél általi súlyos megszegése azzal, hogy az adott szerződésszegés
      az arról szóló értesítés kézhezvételét követő öt (5) napon keresztül
      orvosolatlan marad.
    </p>
    <p>
      <a name="_Hlk514249932">
        4.4.A Felek kötelesek haladéktalanul értesíteni egymást minden olyan
        eseményről, mely a szerződés megszűnésének jogkövetkezményével járhat.
      </a>
    </p>
    <p>
      4.5.Amennyiben a Megállapodásszegés jellege nem zárja ki, a felmondás
      előtt a felmondásra jogosult köteles írásban, és részletesen tájékoztatni
      a Megállapodásszegő felet a kötelezettségszegés tartalmáról és az elvárt
      Megállapodásszerű intézkedés módjáról. A Megállapodásszegő fél köteles
      legkésőbb 30 (harminc) napon belül eleget tenni, vagy a
      Megállapodásszegést más, a másik fél által elfogadható módon orvosolni.
      Amennyiben a Megállapodásszegő fél kötelezettségét a felszólítás ellenére
      sem teljesíti, a másik fél jogosult a Megállapodást írásban felmondani. A
      felmondási idő az írásbeli felmondás kézhezvételi napját követő napon
      kezdődik.
    </p>
    <p>
      4.6.A jelen Megállapodás megszűnése esetén a megszűnés napján a Felek az
      együttműködéssel kapcsolatos, esetlegesen nála lévő iratokat átadják a
      másik félnek és a felek egymással teljes körűen elszámolnak.
    </p>
    <p>
      4.7.A titoktartási kötelezettséggel, kártalanítással és felelősséggel
      kapcsolatos rendelkezések valamint bármely olyan rendelkezés, amely
      természeténél, értelménél fogva vagy a Felek megállapodásának
      következtében ésszerűen a jelen Megállapodás megszűnése után is hatályban
      maradó rendelkezésnek minősül, a jelen Megállapodás megszűnése után is
      hatályban marad.
    </p>
    <p>
      Felek a Megállapodást gondosan elolvasták és megértették, és aláírásukkal
      elismerik, hogy belőle egy-egy példányt megkaptak. A Megállapodást aláíró
      személynek megvan a szükséges felhatalmazása a Megállapodás aláírására.
    </p>
  </div>
)
